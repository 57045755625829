.table table {
    border-collapse: collapse; 
    table-layout: auto;
}

.table .ant-table{
    background: transparent;
}

.table tr.ant-table-row {
    border-top: 10px solid transparent;
    height: 1px;
}

.table .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0;
    height: inherit;
}

.table-container {
    margin-top: 10px;
}

.table-cell {
    background-color: #fff;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 5px 3px 4px rgb(0 0 0 / 25%);
    z-index: 1;
}

.last-cell {
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
}

.first-cell {
    border-bottom-left-radius: 10px; 
    border-top-left-radius: 10px; 
}

.table-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-left: 10px;
}

.table-value {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #636363;
    margin-top: 10px;
}

.table-profile-edit-button {
    border: none;
    color: rgba(13, 23, 35, 0.5);
}

.table-profile-edit-button:hover,
.table-profile-edit-button:active,
.table-profile-edit-button:focus {
    color: #00A19B;
}

@media (min-width:319px) and (max-width: 414px){
    .table-container {
        width: 100%;
        overflow-x: scroll;
    }

    .table table {
        table-layout: fixed !important;
    }
}
@media (min-width:415px) and (max-width:744px){
    .table-container {
        width: 100%;
        overflow-x: scroll;
    }

    .table table {
        table-layout: fixed !important;
    }
}