.app {
  height: 100%;
}

.main {
  margin-left: 200px;
  height: 100%;
}

.app-content {
  padding: 13px 45px;
  min-height: calc(100vh - 167px - 70px);
}

@media (min-width:319px) and (max-width: 533px){
  .app-content {
    padding: 10px;
  }
}

@media (max-width: 991px){
  .main {
    margin-left: 65px;
  }
}