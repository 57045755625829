.container-my-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content-my-profile {
    width: 60%;
}

.avatar-my-prfile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.avatar-my-prfile-style {
    font-size: 86px !important;    
    width: 136px;
    height: 136px;
    line-height: normal;
}

.forms-profile {
    margin-top: 35px;
}

.input-group-profile {
    width: 100%;
    margin: 5px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.profile-name {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-top: 10px;
}

.profile-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

@media (min-width:319px) and (max-width: 414px){
    .content-my-profile {
        width: 90%;
    }

    .avatar-my-prfile-style {
        font-size: 68px !important;
        width: 100px;
        height: 100px;
    }

    .input-group-profile {
        grid-template-columns: 1fr;
    }

    .forms-profile {
        margin-top: 20px;
    }
}
@media (min-width:415px) and (max-width: 575px){
    .content-my-profile {
        width: 90%;
    }
    .avatar-my-prfile-style {
        width: 130px;
        height: 130px;
    }
    .input-group-profile {
        grid-template-columns: 1fr;
    }
}
@media (min-width: 576px) and (max-width:744px){
    .content-my-profile {
        width: 90%;
    }
}
@media (min-width: 745px) and (max-width: 767px){
    .content-my-profile {
        width: 80%;
    }
}
@media (min-width: 768px) and (max-width: 991px){
    .content-my-profile {
        width: 70%;
    }
}