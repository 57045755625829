.pix-receipt-container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 50px;
}

.pix-receipt-search-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
}

.pix-receipt-last-transfer-content {
    margin-top: 30px;
    max-height: 440px;
    overflow-y: scroll;
    display: grid;
    row-gap: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 2px;
}

.pix-receipt-last-transfer-content::-webkit-scrollbar {
    width: 10px;
    height: 15px;
    margin-left: 5px;
}
.pix-receipt-last-transfer-content::-webkit-scrollbar-thumb {
    background: #00a19b;
    border-radius: 38px;
}

.pix-receipt-last-transfer-content::-webkit-scrollbar-track {
    background: #c4c4c4;
    border-radius: 100px;
}

.pix-receipt-last-transfer {
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border:1px solid #fff;
}

.pix-receipt-last-transfer:hover,
.pix-receipt-last-transfer-active {
    border:1px solid #00A19B;
}

.pix-receipt-last-transfer-span {
    font-size: 16px;
    line-height: 25px;
    color: #494949
}

.pix-receipt-last-transfer-span-group {
    display: flex;
    justify-content: space-between;
}

.pix-receipt-last-transfer-title {
    font-weight: 700;
    margin-right: 5px;
}

.pix-receipt-balance {
    border: 2px solid #00A19B;
    border-radius: 5px;
    padding: 10px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #494949;
}

.pix-receipt-balance-button-content {
    display: flex;
    align-items: center;
}

.pix-receipt-balance-button {
    border-radius: 50%;
    background: transparent;
    border: none;
    margin-left: 10px;
}

.pix-receipt-forms {
    margin-top: 20px;
}

.pix-receipt-input-group-two {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin: 10px 0px;
}

.pix-receipt-input-group-three {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    margin: 10px 0px;
}

.pix-receipt-favorite-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
}

.pix-receipt-favorite-label {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}

.pix-receipt-switch.ant-switch-checked {
    background: #00A19B;
}

.pix-receipt-button-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media (max-width: 830px) {
    .pix-receipt-container {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    .pix-receipt-container .pix-receipt-content-item:nth-of-type(1) {
        order: 2;
    }

    .pix-receipt-input-group-two {
        grid-template-columns: 1fr;
    }

    .pix-receipt-search-content {
        grid-template-columns: 1fr;
    }
}