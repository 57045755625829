.add-card-machine-modal .ant-modal-content {
    border-radius: 10px;
}

.add-card-machine-modal .ant-modal-header {
    background-color: #0A1E41;
    border-radius: 10px 10px 0px 0px;
}

.add-card-machine-modal .ant-modal-close-icon {
    color: white
}

.add-card-machine-modal .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.add-card-machine-modal-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-card-machine-modal-footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-card-machine-modal-button-approve {
    background-color: #00C108;
    color: white;
    font-size: 14px;
    font-weight: 600;
    height: 46px;
    padding: 0px 36px;
    border-radius: 5px;
}

.add-card-machine-modal-button-approve:hover {
    background-color: #00C108;
    color: white;
    opacity: 0.8;
}

.add-card-machine-modal-button-reprove {
    font-size: 14px;
    font-weight: 600;
    height: 46px;
    padding: 0px 36px;
    border-radius: 5px;
    background-color: white;
    border: 1.5px solid #FF0000;
    color: #FF0000
}

.add-card-machine-modal-button-reprove:hover {
    background-color: #FF0000;
    border: 1.5px solid white;
    color: white
}

.add-card-machine-modal-body-title {
    color: #0A1E41;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.add-card-machine-modal-body-subtitle {
    color: #0A1E41;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}

.add-card-machine-modal-body-describe {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    border: 1px solid #0A1E41;
    border-radius: 10px;
    padding: 8px 20px;
    margin-top: 20px;
}

.add-card-machine-modal-body-describe-item {
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
}

.add-card-machine-modal-body-describe-item span {
    font-size: 11px;
    color: #0A1E41;
}

.add-card-machine-modal-body-describe-item-title {
    font-weight: 600;
}

.add-card-machine-modal-input-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    margin-bottom: 10px;
}