.page-header-tab.ant-tabs-top > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-bottom > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-top > div > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #C4C4C4;
}

.transactions-button {
    float: right;
    border: 1.5px solid #0a1e41;
    border-radius: 5px;
    color: #0a1e41;
}

.transactions-button:hover {
    background: #0a1e41;
    color: white;
    border-color: white;
}

.transactions-summary-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
    margin: 30px 0px;
}

.transactions-summary-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 25px 0px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.transactions-summary-item-title {
    font-weight: 500;
    font-size: 17px;
    color: #000000;
}

.transactions-summary-item-subtitle {
    font-weight: 500;
    font-size: 14px;
    color: #666666;
}

.transactions-summary-item-value {
    font-weight: 600;
    font-size: 22px;
    color: #666666;
}

.transactions-summary-item-fail {
    margin-top: 18px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FF0F0F;
}

@media (max-width:991px){
    .transactions-table-content {
        overflow-x: scroll;
    }

    .transactions-summary-container {
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 20px;
    }

    .transactions-summary-item-title {
        font-size: 13px;
    }

    .transactions-summary-item-value,
    .transactions-summary-item-fail {
        font-size: 18px;
    }

    .transactions-summary-item-subtitle {
        font-size: 14px;
    }

}