.voucher-container {
    margin-top: 20px;
}

.voucher-filter-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    align-items: center;
}

.voucher-filter-content-button {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 16px;
}

.voucher-filter-button {
    background: #D9D9D9;
    border-radius: 5px;
    padding: 10px 21px;
    height: auto;
    min-width: 76px;
    border: 3px solid transparent;
}

.voucher-filter-button:hover,
.voucher-filter-button:active,
.voucher-filter-button:focus,
.voucher-filter-button-active {
  background: #D9D9D9;
  border: 3px solid #00A19B;
  color: black;
}