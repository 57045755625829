.tax-group-container {
    margin: 6px 0px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
}

.tax-group-content-installment {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-auto-flow: column;
    column-gap: 12px;
    row-gap: 12px;
}

.tax-group-content-button {
    display: flex;
    align-items: center;
    justify-content: center;
}