.chart-container {
    text-align: center;
    position: relative;
    width: 100%;
    height: 450px;
}

.chart-total-content {
    position: absolute;
    inset: 0px 80px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.chart-total-subtitle { 
    color: #4B4B4D;
    font-size: 14px;
    font-weight: 500; 
}

.chart-total-title {
    color: #4B4B4D;
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
}

@media (min-width:319px) and (max-width: 533px) {
    .chart-total-title {
        font-size: 28px;
        line-height: 28px;
    }

    .chart-total-subtitle {
        font-size: 10px;
    }
}