.additional-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 45px;
    column-gap: 45px;
    margin-top: 25px;
}

.additional-avatar-content {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}

.additional-avatar-style {
    font-size: 86px !important;    
    width: 136px;
    height: 136px;
    line-height: normal;
}

.additional-input-group {
    width: 100%;
    margin: 5px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.additional-subtitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
}

.additional-forms-item {
    margin: 10px 0px;
}

.additional-collapse.ant-collapse {
    background: transparent;
    border: none;
}

.additional-collapse.ant-collapse,
.additional-collapse .ant-collapse-content,
.additional-collapse .ant-collapse-item {
    border: none;
}

.additional-collapse .ant-collapse-header {
    background: white;
    border-radius: 5px !important;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
    margin-top: 10px;
}

.additional-collapse .ant-collapse-content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    border-radius: 0px 0px 5px 5px;
    background: #0A1E41;
    color: white;
}

.additional-collpase-input-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.additional-collapse-input-label {
    margin-right: 5px;
}

.additional-collapse-switch.ant-switch-checked {
    background: #00A19B;
}

.additional-content-button {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

@media (min-width:319px) and (max-width: 414px){
    .additional-container {
        grid-template-columns: 1fr;
    }

    .additional-avatar-style {
        font-size: 68px !important;
        width: 100px;
        height: 100px;
    }

    .additional-input-group {
        grid-template-columns: 1fr;
    }
}
@media (min-width:415px) and (max-width:744px){
    .additional-container {
        grid-template-columns: 1fr;
    }

    .additional-avatar-style {
        width: 130px;
        height: 130px;
    }

    .additional-input-group {
        grid-template-columns: 1fr ;
    }
}
@media (min-width: 745px) and (max-width: 1199px){
    .additional-container {
        grid-template-columns: 1fr;
    }
}