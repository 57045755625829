.input-checkbox .ant-checkbox-inner {
    height: 21px;
    width: 21px;
}

.input-checkbox .ant-checkbox {
    top: auto;
}

.input-checkbox.ant-checkbox-wrapper {
    align-items: center;
}

.input-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00A19B;
    border-color: #00A19B;
}