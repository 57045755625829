.input {
    border-radius: 5px;
    height: 56px;
}

.input .ant-input {
    background: transparent;
    color: rgba(255, 255, 255, 0.85);
}

.input-password-ghost {
    background: rgba(239, 239, 255, 0.1);
    border: 1px solid #FFFFFF;
    color: rgba(255, 255, 255, 0.85);
}

.input-password-primary {
    border: none;
}

.input-password-primary .ant-input {
    color: black;
}

.error {
    border: 1px solid #FF0000
}

.success {
    border: 1px solid #00C108
}

.helper-text {
    background-color: #fff;
    border-radius: 5px;
    padding: 6px 0px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    animation: horizontal-shaking 0.5s;
}

.input .ant-btn {
    border: none
}
.input-password-ghost .ant-btn {
    background: transparent;
}

.button-input .ant-btn-circle.ant-btn-lg {
    background: transparent;
    border: none;
}

.button-input:hover,
.button-input.ant-btn:active,
.button-input.ant-btn:focus {
    background: transparent;
    color: #00A19B;
}

@keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
}