.page-header-tab.ant-tabs-top > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-bottom > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-top > div > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #C4C4C4;
}

.balance-payment-button {
    float: right;
    border: 1.5px solid #0a1e41;
    border-radius: 5px;
    color: #0a1e41;
}

.balance-payment-button:hover {
    background: #0a1e41;
    color: white;
    border-color: white;
}

@media (max-width:991px){
    .balance-table-content {
        overflow-x: scroll;
    }
}