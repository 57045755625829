.container-input {
    width: 100%;
}

.input {
    border-radius: 5px;
    height: 56px;
}

.input-ghost {
    background: rgba(239, 239, 255, 0.1);
    border: 1px solid #FFFFFF;
    color: rgba(255, 255, 255, 0.85);
}

.input-primary {
    border: none;
}

.input-secondary {
    border: none;
    background: #D9D9D9;
}

.input.input-primary .ant-input {
    color: black;
}

.input-primary.ant-input-disabled,
.input-primary.ant-input-affix-wrapper-disabled,
.input-primary.ant-picker-disabled {
  background-color: #D9D9D9 !important;
}

.error {
    border: 1px solid #FF0000
}

.success {
    border: 1px solid #00C108
}

.button-input {
    background: transparent;
    border: none;
}

.button-input:hover,
.button-input.ant-btn:active,
.button-input.ant-btn:focus {
    background: transparent;
    color: #00A19B;
}

.input-title {
    color: #888888;
    display: block;
}

.helper-text {
    background-color: #fff;
    border-radius: 5px;
    padding: 6px 0px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    animation: horizontal-shaking 0.5s;
}

@keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
}