.pix-qr-code-container {
    background: linear-gradient(104.04deg, #0A1E41 0%, #00A19B 100%), #D9D9D9;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}

.pix-qr-code-print {
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(104.04deg, #0A1E41 0%, #00A19B 100%), #D9D9D9;
    border-radius: 10px;
}

.pix-qr-code-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pix-qr-code-title {
    margin: 20px 0px;
    color: white;
}

.pix-qr-code {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.pix-qr-code-button-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
}

.pix-qr-code-button {
    background-color: #00A19B;
    color: white;
    font-weight: 500;
    border: none
}

.pix-qr-code-button:hover {
    background-color: #00A19B;
    color: white;
    opacity: 0.8;
}

.pix-qr-code-button:active {
    background-color: #001529;
    color: white;
    opacity: 0.8;
}

@media (min-width:319px) and (max-width: 414px){
    .pix-qr-code-button-container {
        grid-template-columns: 1fr;
        row-gap: 10px;
    }

    .pix-qr-code-container {
        padding-bottom: 20px;
    }
    
    .pix-qr-code-print {
        padding-top: 20px;
    }
}