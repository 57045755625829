.pix-table table {
    border-collapse: collapse; 
    table-layout: auto;
}

.pix-table .ant-table{
    background: transparent;
}

.pix-table tr.ant-table-row {
    border-top: 10px solid transparent;
    height: 1px;
}

.pix-table .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0;
    height: inherit;
}

.pix-table-container {
    margin-top: 10px;
}

.pix-table-cell {
    background-color: #fff;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    z-index: 1;
}

.last-cell {
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
}

.first-cell {
    border-bottom-left-radius: 10px; 
    border-top-left-radius: 10px; 
}

.pix-table-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-left: 10px;
}

.pix-table-value {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #636363;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pix-table-description {
    background: #0A1E41;
    margin-top: -10px;
    padding: 25px 40px 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.pix-table-description-title {
    margin: 0;
    margin-bottom: 10px;
    color: white;
}

.pix-table-description-value {
    color: white;
}

.pix-table-status {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.pix-table-status .pix-table-value {
    font-weight: 600;
}

.pix-table-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.pix-table-aprove {
    background: #00C108;
    color: white;
    font-weight: 600;
    border-color: transparent;
    margin-right: 15px;
}

.pix-table-aprove:hover,
.pix-table-aprove:active,
.pix-table-aprove:focus {
    background: #00C108;
    opacity: 0.8;
    color: white;
    font-weight: 600;
    border-color: transparent;
}

.pix-table-refuse {
    background: #FF0000;
    color: white;
    font-weight: 600;
    border-color: transparent;
    margin-right: 15px;
}

.pix-table-refuse:hover,
.pix-table-refuse:active,
.pix-table-refuse:focus {
    background: #FF0000;
    opacity: 0.8;
    color: white;
    font-weight: 600;
    border-color: transparent;
}

.pix-table-conclude {
    background: #ff8100;
    color: white;
    font-weight: 600;
    border-color: transparent;
    margin-right: 15px;
}

.pix-table-conclude:hover,
.pix-table-conclude:active,
.pix-table-conclude:focus {
    background: #ff8100;
    opacity: 0.8;
    color: white;
    font-weight: 600;
    border-color: transparent;
}

@media (min-width:319px) and (max-width: 414px){
    .pix-table-container {
        width: 100%;
        overflow-x: scroll;
    }

    .pix-table table {
        table-layout: fixed !important;
    }
}
@media (min-width:415px) and (max-width:744px){
    .pix-table-container {
        width: 100%;
        overflow-x: scroll;
    }

    .pix-table table {
        table-layout: fixed !important;
    }
}
