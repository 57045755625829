.simulator-container {
  margin-top: 20px;
}

.simulator-header {
  display: flex;
  flex-direction: column;
}

.simulator-header-title {
  font-size: 24px;
}

.simulator-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 100px;
  margin-top: 20px;
}

.simulator-menu {
  content: '';
}

.simulator-menu-item {
  margin-bottom: 20px;
}

.simulator-menu-item-button-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}

.simulator-menu-item-multiple-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 10px;
}

.simulator-menu-item-button {
  background: #D9D9D9;
  border-radius: 5px;
  padding: 10px 21px;
  height: auto;
  min-width: 76px;
  border: 3px solid transparent;
}

.simulator-menu-item-button:hover,
.simulator-menu-item-button:active,
.simulator-menu-item-button:focus,
.simulator-menu-item-button-active {
  background: #D9D9D9;
  border: 3px solid #00A19B;
  color: black;
}

.simulator-result {
  content: '';
}

.simulator-result-content {
  height: 280px;
  overflow: overlay;
  margin-top: 20px;
}

.simulator-result-content::-webkit-scrollbar {
  width: 10px;
  height: 15px;
  margin-left: 5px;
}
.simulator-result-content::-webkit-scrollbar-thumb {
  background: #00a19b;
  border-radius: 38px;
}

.simulator-result-content::-webkit-scrollbar-track {
  background: #c4c4c4;
  border-radius: 100px;
}

.simulator-result-content-item {
  display: flex;
  justify-content: space-around;
}

.simulator-result-content-describe {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.simulator-result-content-describe-title {
  font-size: 14px;
  font-weight: 700;
  color: #0A1E41;
  margin-bottom: 10px;
}

.simulator-result-divider {
  height: 2px;
  background: linear-gradient(102.33deg, rgba(0, 161, 155, 0) 11.96%, #0A1E41 112.91%);
  border-radius: 5px;
  margin: 10px 0px;
  width: 95%;
}

@media (max-width:991px){
  .simulator-content{
    grid-template-columns: 1fr;
  }

  .simulator-menu-item-multiple-buttons {
    grid-template-columns: repeat(3, 1fr);
  }
}