.client-information-cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 18px;
    margin: 20px 0px;
}

.client-information-card-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #fff;
}

.client-information-card-item-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
}

.client-information-card-item-label {
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #494949;
}

.tax-group-card {
    cursor: pointer;
}

.tax-group-card:hover,
.tax-group-card:active,
.tax-group-card:focus {
    border: 1px solid #00A19B;
}

.page-header-tab.ant-tabs-top > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-bottom > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-top > div > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #C4C4C4;
}

.client-information-payment-button {
    float: right;
    border: 1.5px solid #0a1e41;
    border-radius: 5px;
    color: #0a1e41;
}

.client-information-payment-button:hover {
    background: #0a1e41;
    color: white;
    border-color: white;
}

@media (max-width:991px){

    .client-information-cards-container {
        grid-template-columns: 1fr;
        row-gap: 18px;
    }

    .client-information-table-content {
        overflow-x: scroll;
    }
}