.page-header.ant-page-header {
    padding: 0;
}

.page-header .ant-page-header-heading-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
}

.ant-page-header-back-button:hover {
    color: #00A19B;
}

.page-header-arrow {
    font-size: 26px;
    margin-right: 25px;
}

@media (min-width:319px) and (max-width: 533px){
    .page-header .ant-page-header-heading-title {
        font-size: 30px;
    }

    .page-header-arrow {
        margin-right: 5px;
    }
}