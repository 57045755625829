.header {
    height: 171px;
    background: linear-gradient(104.04deg, #0A1E41 0%, #00A19B 100%), #D9D9D9;
}

.ant-dropdown-menu {
    background-color: #0A1E41;
    padding: 32px 24px;
    border-radius: 5px;
}

.ant-dropdown-arrow::before {
    background-color: #0A1E41;
}

.ant-dropdown-menu-item, 
.ant-dropdown-menu-submenu-title {
    color: white;
}

.ant-dropdown-menu-item:hover, 
.ant-dropdown-menu-submenu-title:hover, 
.ant-dropdown-menu-item.ant-dropdown-menu-item-active, 
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active, 
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active, 
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-item-selected {
    background-color: transparent;
}

.label {
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    margin-right: 10px;
    color: white;
}