.home-card-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    margin-top: 12px;
}

.home-card-group-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    margin-top: 12px;
}

.home-card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    padding: 16px;
}

.home-card-describe-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #0A1E41;
}

.home-card-title {
    font-size: 18px;
    font-weight: 500;
}

.home-card-describe {
    font-size: 24px;
    font-weight: 700;
}

.home-card-img {
    margin-top: 6px;
    width: 80px;
}

.home-content-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
    margin-top: 24px;
}

.home-content-subtitle {
    color: #0A1E41;
    font-weight: 500;
    font-size: 24px;
}

.home-content-ranking {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
}

.home-content-ranking-bar-chart {
    height: 450px;
}

.home-content-ranking-summary-content {
    padding: 0px 40px 40px;
}

.home-content-ranking-summary-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    padding: 0px 40px;
}

.home-content-ranking-summary-item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E3E6E9;
    border-radius: 4px;
}

.home-content-ranking-summary-item-text {
    color: #707070;
    opacity: 0.6;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.home-content-ranking-summary-item-value {
    color: #4B4B4D;
    font-weight: 500;
    font-size: 14px;
}

.home-content-ranking-summary-item-span {
    position: relative;
    top: 15px;
    left: 5px;
    font-weight: 600;
    font-size: 24px;
}

@media (min-width:319px) and (max-width: 533px) {
    .home-card-group {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        row-gap: 10px;
    }

    .home-card-title {
        font-size: 13px;
    }

    .home-card-describe {
        font-size: 18px;
    }

    .home-card-img {
        margin-top: 0px;
        width: 50px;
    }

    .home-content-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .home-content-ranking-summary-content {
        padding: 0;   
    }

    .home-content-ranking-summary-grid {
        grid-template-columns: repeat(1, 1fr);
        padding: 0px 40px 30px;
        column-gap: 0
        
    }
}

/*
.card-grid {
    display: grid;
    column-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 30px;
}

.card-item {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.card-item.balance::before {
    content: '';
    background-image: url(../../../static/balance.png);
    background-size: 90px 54px;
    width: 90px;
    height: 54px;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.card-item.in::before {
    content: '';
    background-image: url(../../../static/in.png);
    background-size: 90px 54px;
    width: 90px;
    height: 54px;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.card-item.out::before {
    content: '';
    background-image: url(../../../static/out.png);
    background-size: 90px 54px;
    width: 90px;
    height: 54px;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.card-item.machine-models::before {
    content: '';
    background-image: url(../../../static/machine_models.png);
    background-size: 52px 52px;
    width: 52px;
    height: 52px;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.card-title {
    font-weight: 600;
    font-size: 18px;
}

.card-value {
    font-weight: 700;
    font-size: 24px;
    color: #666;
}

.card-item .ant-card-body {
    padding-bottom: 10px;
}

.card-item .ant-row {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-bar-chart {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
}

.container-bar-chart {
    background: #FFFFFF;
    box-shadow: 0px 0.995173px 3.98069px rgb(0 0 0 / 25%);
    border-radius: 5px;
}

.menu-bar-chat {
    width: 100%;
    margin-top: 20px;
    margin-right: 25px;
}

.button-menu-bar-chat {
    margin: 5px;
    background: #D9D9D9;
    border-radius: 5px;
    padding: 6px 16px;
    height: auto;
    min-width: 76px;
    border: 3px solid transparent;
}

.button-menu-bar-chat:hover,
.button-menu-bar-chat:active,
.button-menu-bar-chat:focus,
.button-menu-bar-chat.active {
  background: #D9D9D9;
  border: 3px solid #00A19B;
  color: black;
  filter: none;
}



.chart {
    margin-top: 30px;
}

@media (min-width:319px) and (max-width: 533px){
    .card-grid {
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 20px;
    }
    .card-item.balance::before {
        background-size: 55px 30px;
        background-repeat: no-repeat;
        width: 55px;
        height: 30px;
    }
    
    .card-item.in::before {
        background-size: 55px 30px;
        background-repeat: no-repeat;
        width: 55px;
        height: 30px;
    }
    
    .card-item.out::before {
        background-size: 55px 30px;
        background-repeat: no-repeat;
        width: 55px;
        height: 30px;
    }
    
    .card-item.machine-models::before {
        background-size: 30px 30px;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
    }
    .card-value {
        font-size: 13px;
    }
    .card-title {
        font-size: 11px;
    }
    .card-item .ant-card-body {
        padding: 1px;
    }
    .content-bar-chart {
        height: 350px;
    }
    .chart {
        display: none;
    }
}
@media (min-width: 534px) and (max-width: 767px){
    .card-grid {
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
        column-gap: 20px;
    } 
}
*/