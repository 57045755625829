.clients-forms-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
    margin-top: 20px;
}

.clients-forms-item {
    border: 1.5px solid #001529;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
}

.clients-forms-item-paragraph-container {
    margin-top: 46px;
    padding-left: 24px;
    padding-right: 32px;
}

.clients-forms-item-paragraph-content {
    background-color: #001529;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clients-forms-item-paragraph-text {
    color: #fff;
    font-size: 30px;
}

.clients-forms-item-container {
    display: block;
    width: 100%;
    padding: 24px 24px 24px 0px;

}

.clients-forms-item-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 10px;
    margin-top: 16px;
}

.clients-forms-item-input-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
}

.clients-forms-item-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001529;
}

.clients-forms-button-content {
    display: flex;
    justify-content: center;
}

@media (max-width: 991px){
    .clients-forms-item-paragraph-container {
        padding: 0px 16px;
    }

    .clients-forms-item-paragraph-content {
        width: 35px;
        height: 35px;
    }

    .clients-forms-item-paragraph-text {
        font-size: 22px;
    }

    .clients-forms-item-content,
    .clients-forms-item-input-group {
        grid-template-columns: repeat(1, 1fr);
    }
}