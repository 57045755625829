.pix-table-upload {
    color: white;
    border-color: transparent;
    margin-left: 15px;
}

.pix-table-upload-dark {
    color: #747474
}

.pix-table-upload:hover{
    color: #00A19B;
    border-color: #00A19B;
}