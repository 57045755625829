.container-button {
    border-radius: 5px;
}

.primary {
    background: #0A1E41;
    color: white;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    height: 53px;
}

.secondary {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #0A1E41;
    height: 36px;
}

.primary:hover,
.primary:active,
.primary:focus,
.secondary:hover,
.secondary:active,
.secondary:focus {
    background: #00A19B;
    color: white;
}