.input-select {
    height: 56px;
    width: 100%;
}

.input-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
    border-radius: 5px;
    border: none;
}

.input-select-secondary.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #D9D9D9;
}

.input-select .ant-select-selection-placeholder,
.input-select .ant-select-selection-item {
    display: flex;
    align-items: center;
}