.token {
  --ReactInputVerificationCode-itemWidth: 59px;
  --ReactInputVerificationCode-itemHeight: 56px;
}
  
.token .ReactInputVerificationCode__item {
  border-radius: 5px;
  font-size: 20px;
  font-weight: 400;
  box-shadow: inset 0 0 0 1px #ccc;
  color: white;
  background: rgba(239, 239, 255, 0.1);
}

.token .ReactInputVerificationCode__item.is-active {
  box-shadow: inset 0 0 0 2px #00a19b;
}

.error {
  border: 1px solid #FF0000
}

.success {
  border: 1px solid #00C108
}

.helper-text {
  background-color: #fff;
  border-radius: 5px;
  padding: 6px 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  animation: horizontal-shaking 0.5s;
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
}

@media (min-width:319px) and (max-width: 414px){
  .token {
    --ReactInputVerificationCode-itemWidth: 50px;
    --ReactInputVerificationCode-itemHeight: 50px;
  }
  .token .ReactInputVerificationCode__container{
    width: 100%;
  } 
}
@media (min-width: 415px) and (max-width: 575px){
  .token {
    --ReactInputVerificationCode-itemWidth: 50px;
    --ReactInputVerificationCode-itemHeight: 50px;
  }
}
@media (min-width: 576px) and (max-width:744px){
  .token {
    --ReactInputVerificationCode-itemWidth: 50px;
    --ReactInputVerificationCode-itemHeight: 50px;
  }
}
