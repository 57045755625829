.card-profile-container {
    display: flex;
    align-items: center;
}


.card-profile-avatar {
    font-size: 86px !important;    
    width: 136px;
    height: 136px;
    line-height: revert;
    z-index: 1;
}

.card-profile-content {
    margin-left: -40px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 16px 0px;
    padding-left: 65px;
    padding-right: 25px;
    display: flex;
    align-items: center;
}

.card-profile-content-text {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
}

.card-profile-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
}

.card-profile-subtitle {
    font-weight: 600;
}

@media (min-width:319px) and (max-width: 414px){
    .card-profile-avatar {
        font-size: 68px !important;
        width: 100px;
        height: 100px;
    }
    .card-profile-content{
        padding: 5px 0px;
        padding-left: 45px;
        padding-right: 5px;
        width: calc(100% - 50px);
    }
    .card-profile-title {
        margin-top: 0px !important;
    }
    .card-profile-describe {
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}