.sidebar {
    position: fixed;
    height: 100%;
}

.sidebar .ant-layout-sider-children{
    display: flex;
    flex-direction: column;
}
  
.sidebar .ant-image {
    height: 167px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.sidebar .ant-menu  {
    background-color: transparent;
    color: white;
}

.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover, 
.ant-menu-light .ant-menu-item-active, 
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
.ant-menu-light .ant-menu-submenu-active, 
.ant-menu-light .ant-menu-submenu-title:hover {
    background-color: #00A19B;
    color: white;
}

.sidebar .ant-menu-item:hover {
    color: white;
}

.sidebar .ant-menu-inline .ant-menu-item, 
.sidebar .ant-menu-inline .ant-menu-submenu-title {
    width: auto
}

.sidebar .ant-menu-inline .ant-menu-selected::after, 
.sidebar .ant-menu-inline .ant-menu-item-selected::after, 
.sidebar .ant-menu-vertical .ant-menu-item::after, 
.sidebar .ant-menu-vertical-left .ant-menu-item::after, 
.sidebar .ant-menu-vertical-right .ant-menu-item::after, 
.sidebar .ant-menu-inline .ant-menu-item::after {
    display: none;
}

.sidebar .ant-menu-inline, 
.sidebar .ant-menu-vertical, 
.sidebar .ant-menu-vertical-left {
    border: none
}

@media (max-width: 991px){
    .sidebar .ant-menu.ant-menu-inline-collapsed > .ant-menu-item, 
    .sidebar .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, 
    .sidebar .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, 
    .sidebar .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
        padding: 5px 30%;
    }  
}