.reset-password-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-wrap: wrap;
}

.main-card {
  width: 20%;
}