.page-header-tab.ant-tabs-top > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-bottom > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-top > div > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #C4C4C4;
}

.clients-filter-content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 10px;
    width: 50%;
    margin-bottom: 15px;
}

.clients-button {
    float: right;
    border: 1.5px solid #0a1e41;
    border-radius: 5px;
    color: #0a1e41;
    height: 56px;
}

.clients-button:hover {
    background: #0a1e41;
    color: white;
    border-color: white;
}

@media (max-width:991px){
    .clients-content {
        overflow-x: scroll;
    }

    .clients-filter-content {
        grid-template-columns: 1fr;
        row-gap: 10px;
        width: 100%;
    }
}