.profile-container {
    margin-top: 10px;
}

.profile-content {
    margin-top: 25px;
}

.profile-additional-users-title-content {
    display: flex;
    align-items: center;
}

.profile-additional-users-title {
    margin: 0;
    margin-right: 15px;
}